.enhanced-delivery-page {
  &.checkout {
    &.active-panel-shipping {
      .saved-billing-address {
        #billing-panel-edit {
          #{$rdirection}: 17px;
          top: 65px;
          @include breakpoint($width-xlarge) {
            #{$rdirection}: -6px;
          }
        }
      }
    }
    .checkout-shipping-panel-section {
      .select-address {
        margin-top: 16px;
      }
    }
    .responsive-container.checkout {
      padding: 0;
      .checkout__content {
        #shipping-panel {
          &.panel {
            .edit-address-container,
            .new-address-container,
            .billing-address-form-fields {
              overflow: visible;
              .field-container {
                .form-item {
                  &.select {
                    &::after {
                      #{$rdirection}: 25px;
                      border: 5px solid transparent;
                      border-top-color: $color--black;
                      bottom: 20px;
                      content: '';
                      pointer-events: none;
                      position: absolute;
                    }
                    select {
                      background-image: none;
                    }
                    .select2-container {
                      border-color: $color--gray--dusty--disable;
                      width: 100%;
                      @include breakpoint($width-largest) {
                        border-color: $color--gray--lighter;
                      }
                      .select2-arrow {
                        b::before {
                          content: '';
                        }
                      }
                    }
                  }
                  &.country-id {
                    &::after {
                      display: none;
                    }
                  }
                }
              }
            }
            .form-container {
              .field-container--grid {
                &.country {
                  p {
                    background: none;
                    border: 0;
                    height: auto;
                    margin: 0;
                    padding: 0;
                  }
                }
              }
            }
            .form-container,
            .billing-address-form-fields {
              .field-container--grid {
                &.city-state-zip {
                  .form-item {
                    &.city-lookup,
                    &.town-lookup {
                      width: 100%;
                    }
                  }
                }
                &.country {
                  @include swap_direction(padding, 16px 20px 14px 15px);
                  background: $color--gray--thick;
                  border: 1px solid $color--very--light--grey;
                  margin-bottom: 22px;
                  .country-id {
                    height: auto;
                    margin: 0;
                    label {
                      .label-content {
                        display: block;
                      }
                    }
                  }
                }
              }
              .address4_container.form-item {
                display: none;
              }
              .field-container.field-container--grid {
                overflow: visible;
              }
            }
          }
          .content {
            .checkout__subtitle {
              padding-#{$ldirection}: 16px;
              @include breakpoint($width-largest) {
                padding-#{$ldirection}: 0;
              }
            }
            .saved-billing-address,
            .billing-address-form-fields {
              .checkout__subtitle {
                padding-#{$ldirection}: 0;
              }
            }
          }
        }
      }
      .legal_frame {
        padding: 15px;
        #legal_iframe {
          margin-bottom: 0;
        }
      }
      .links-panel {
        .links_list {
          .link {
            &.chat {
              display: none;
            }
          }
        }
      }
      .edit-shipping-address {
        .default_ship_container {
          overflow: visible;
        }
      }
    }
  }
  .checkout__sidebar.right {
    .checkout-panel {
      &--loyalty-points {
        padding-bottom: 20px;
      }
    }
    #shopping-bag-wrapper-panel {
      .panel.shopping-bag-panel {
        .item-count__edit {
          .shopping-bag-edit-icon {
            #{$ldirection}: calc(100% - 20px);
            position: relative;
          }
        }
      }
    }
  }
}
