.profile-info__fieldset {
  .form_title {
    width: 60% !important;
    label {
      display: block;
    }
  }
  .form_birthday {
    label {
      display: block;
    }
  }
}

#address_form_container {
  .country_container {
    label {
      display: inline-block;
    }
  }
}

.cart-item {
  .product-info__spp-status {
    position: absolute;
    bottom: 0;
  }
}
