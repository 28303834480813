li.site-navigation__item,
li.site-navigation__more {
  font-size: 14px !important;
  &.site-navigation__more {
    ul {
      > li {
        font-size: 14px !important;
      }
    }
  }
}

.site-header {
  li.site-navigation__more {
    @include breakpoint($bp--xxlarge-up) {
      @include swap_direction(padding, 0 23px);
    }
  }
}

.page-product {
  .sspp-cross-sells__content {
    .product-brief-carousel {
      .product-inventory-status {
        &__sold-out-text {
          height: auto;
          width: 250px;
          line-height: 27px;
          padding: 2px 30px;
        }
      }
    }
  }
}
