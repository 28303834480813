.legal_frame {
  width: 100%;
  height: 10rem;
  border: 1px solid $color--gray--dark;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 90%;
  overflow-y: auto;
  overflow-x: hidden;
  word-break: break-word;
}

.presales-container {
  word-break: break-word;
}

.disclaimer_tooltip {
  padding-bottom: 10px;
}

.section-data-privacy-statement {
  .site-header,
  .site-footer {
    display: none;
  }
  .LPMcontainer {
    display: none !important;
  }
  ol {
    list-style: lower-roman;
    padding-#{$ldirection}: 20px;
  }
  td {
    padding: 5px;
  }
}

.page-node-5608,
.section-data-privacy-statement {
  dl,
  ol,
  ul {
    @include swap_direction(padding, 0 0 0 40px);
  }
}

.sign-in-page {
  .social-login {
    .tooltip {
      margin-top: 23px;
    }
    .fb-social-tooltip-disclaimer {
      margin-top: 15px;
    }
  }
}

#power_review_container {
  .social-login {
    .tooltip {
      margin: 5px 0;
    }
  }
  .pr-row_adv-legal {
    .picker {
      .picker-label {
        max-width: 85%;
      }
    }
  }
}

.enhanced_signin_page {
  .checkout {
    #sign-in-panel {
      .legal_frame {
        margin: 0 auto;
        margin-top: 5px;
        @include breakpoint($bp--medium-up) {
          width: 70%;
        }
      }
    }
  }
  .responsive-container {
    .checkout {
      .checkout {
        &__content {
          #sign-in-panel {
            .social-login {
              .social-login {
                &__container {
                  .fb-social-tooltip-container {
                    .fb-social-tooltip-disclaimer {
                      display: block;
                      margin-bottom: 5px;
                    }
                  }
                  .tooltip {
                    margin-#{$ldirection}: 0;
                    @include breakpoint($bp--medium-up) {
                      margin-#{$ldirection}: 15px;
                    }
                  }
                }
              }
            }
            .checkout {
              &__return-user {
                #checkout_signin {
                  .form-item {
                    .picker {
                      clear: both;
                      margin-bottom: 0;
                      .label {
                        height: 90px;
                        max-width: 100%;
                        padding: 0;
                        margin-top: auto;
                        .label-content {
                          display: block;
                        }
                      }
                      .picker-label {
                        &.error {
                          color: $color--error;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.checkout-confirmation-page {
  .adpl {
    input {
      &:required {
        &:invalid {
          &:not(:checked) {
            &.checkedEmpty {
              + input {
                + div {
                  label {
                    color: $color--error;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.new-account,
.return-user {
  &__legal {
    font-size: 10px;
  }
}
