.section-stores {
  .locations_map_panel {
    .gmnoprint {
      div[title^='Pan left'],
      div[title^='Pan down'],
      div[title^='Pan right'],
      div[title^='Pan up'] {
        opacity: 0 !important;
        filter: alpha(opacity=0) !important;
      }
    }
  }
}

.site-container {
  .product--teaser {
    .product__rating {
      max-width: 100px;
      min-width: 100px;
    }
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .store-locator {
    .form--search--advanced {
      .toggle-wrapper {
        margin: 0px 0px 22px;
      }
      .form--inline {
        .form-type-textfield {
          margin-bottom: 22px;
        }
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .responsive-container.checkout,
  .checkout-confirmation-page .responsive-container {
    padding: 0 15px;
  }
  .checkout {
    .offer-code-panel.accordionPanel {
      header {
        padding: 0 1rem;
        h2.offer-code-panel__title {
          width: 100%;
        }
      }
    }
  }
  .collection-teaser-block {
    .shop-link {
      .btn {
        font-size: 13px;
      }
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .collection-teaser-block {
    .shop-link {
      .btn {
        width: 100%;
      }
    }
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
  }
}

.form--newsletter__form-items {
  .btn {
    line-height: 0px;
  }
}

#popover_container {
  .email-sms-signup-popup-v1 {
    .form--newsletter__form-items {
      .form-text {
        padding: 10px 13px;
      }
    }
  }
}

.new-account,
.return-user {
  &__mobile {
    width: 50%;
  }
}

.social-login {
  &__terms {
    .tooltip {
      width: 50%;
    }
  }
}
