.product--teaser {
  .product__rating {
    @include breakpoint($bp--xlarge-up) {
      max-width: 60px;
      min-width: 60px;
    }
  }
}

.site-container {
  .grid--mpp__item {
    .product__header {
      .product__name {
        padding-right: 60px;
      }
    }
  }
}

.shade-picker__colors-mask {
  width: 100%;
}
