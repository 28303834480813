.enhanced_cart_page {
  .viewcart {
    .checkout__content {
      #viewcart-panel {
        .cart-item__discount-percentage {
          display: block;
          font-size: 10px;
          margin-top: 3px;
          text-align: center;
        }
      }
    }
    .tax-percent {
      &::before {
        content: '%';
        position: relative;
        left: 2px;
      }
      &::after {
        content: ' ';
      }
    }
  }
}
