.sign-in-page,
.site-my-mac-v1 {
  .social-login {
    &__email-opt-in {
      @include breakpoint($bp--medium-up) {
        padding-top: 20px;
      }
      @include breakpoint($bp--xxlarge-up) {
        padding-#{$ldirection}: 52px;
      }
    }
  }
  .gnav {
    .social-login__divider {
      .inner {
        padding: 8px 1px;
      }
    }
  }
}
/* SignIn page styling */
.sign-in-page {
  @include breakpoint($bp--medium-up) {
    .social-login-section {
      margin-#{$ldirection}: 50px;
      @include breakpoint($bp--xxlarge-up) {
        margin-#{$ldirection}: 0;
      }
    }
  }
  .social-login {
    .social-login__divider {
      .inner {
        padding: 8px 2px;
      }
    }
  }
  .divider {
    .social-login__divider {
      .inner {
        padding: 0 8px;
        @include breakpoint($bp--medium-up) {
          padding: 8px 2px;
          // Altered (or) text divider, to display at center.
          #{$rdirection}: -25px;
        }
      }
    }
  }
}
/* Checkout page styling */
.checkout {
  &__content {
    .signin-panel,
    .sign-in-panel {
      .social-login {
        &__divider {
          .inner {
            padding: 8px 2px;
          }
        }
      }
    }
  }
}
/* Appointment booking */
.field-collection-slides {
  .appt-book {
    .social-login {
      .inner {
        padding: 8px 1px;
      }
    }
  }
}
/* Order Confirmation Page */
.checkout-confirmation-page {
  .social-login {
    .tooltip {
      display: block;
    }
  }
}
