$tier1color: #f7b7d3;
$tier2color: #ed2024;
$tier3color: #bfbfc3;

#loyalty__panel__points {
  display: none;
}

#loyalty-offer-code-panel {
  margin: 0;
  .loyalty-offer-code-panel {
    &__title,
    &__points {
      font-family: $roboto-condensed-bold-font;
    }
  }
  .offer-code-content__wallet {
    width: 100%;
    .slick-list {
      @include breakpoint($bp--medium-portrait) {
        padding: 0 !important;
        width: 80%;
      }
    }
    .slick-slide {
      @include breakpoint($bp--medium-portrait) {
        padding: 0;
        min-height: 525px;
      }
      min-height: 490px;
      .offer-code-wallet {
        &__button {
          min-width: auto;
          height: 36px;
          padding: 7px 25px;
          line-height: inherit;
          position: absolute;
          bottom: 0;
          display: block;
          &.disabled {
            pointer-events: none;
            background: $color--gray--lightest;
          }
        }
        &__code__description {
          &-points {
            font-size: 17px;
            position: absolute;
            bottom: 40px;
            width: 100%;
          }
          &-expiration {
            font-size: 14px;
          }
        }
        &__code__image-container {
          min-height: 190px;
          border: none !important;
          .offer_image {
            margin: 0 auto;
          }
        }
      }
    }
    .loyalty-offer-code-panel {
      &__select {
        font-size: 14px;
        margin-bottom: 20px;
      }
    }
  }
  .offer-code-wallet__code__description {
    font-weight: normal;
    &-title,
    &-points {
      font-weight: normal;
    }
    &-title {
      font-size: 18px;
      min-height: 40px;
      @include breakpoint($bp--medium-portrait) {
        min-height: 30px;
      }
    }
    &-description {
      @include breakpoint($bp--medium-portrait) {
        min-height: 40px;
      }
    }
  }
  .loyalty__panel__offers {
    &__loader__img {
      margin: 0 auto;
    }
  }
}

.loyalty__content-bottom {
  .macselect-benefits {
    .mac-select__marketing-page {
      &__title {
        width: 37%;
        font-family: $roboto-condensed-bold-font;
        @include breakpoint($bp--medium-portrait) {
          width: 70%;
        }
      }
      &__romance-header {
        width: 40%;
        font-family: $roboto-condensed-bold-font;
        @include breakpoint($bp--medium-portrait) {
          width: 60%;
        }
      }
      &__romance-text {
        font-family: $roboto-mono_regular-font;
      }
      .mac-select__marketing-page__header-col2 {
        .mac-select__marketing-page__signin {
          font-size: 17px;
        }
      }
      .macselect-benefits__container {
        .macselect-benefits__section {
          &-levels__header-status {
            font-size: inherit;
          }
          &-fields {
            width: 50%;
          }
          &-levels {
            width: 50%;
          }
          &-level1 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $tier1color;
              }
              &-copy {
                li {
                  span {
                    background-color: $tier1color;
                  }
                }
              }
            }
          }
          &-level2 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $tier2color;
              }
              &-copy {
                li {
                  span {
                    background-color: $tier2color;
                  }
                }
              }
            }
          }
          &-level3 {
            .macselect-benefits__section {
              &-levels__header {
                background-color: $tier3color;
              }
              &-copy {
                li {
                  span {
                    background-color: $tier3color;
                  }
                }
              }
            }
          }
          &-level1,
          &-level2,
          &-level3 {
            width: 33.33%;
          }
          &-copy {
            ul {
              li {
                height: 110px;
                padding: 13px 0;
                display: flex;
                align-items: center;
                @include breakpoint(($bp--large-up) (orientation landscape)) {
                  height: 70px;
                }
                .text_content {
                  background: none;
                  width: auto;
                  &.benefits-content {
                    padding-#{$ldirection}: 23px;
                  }
                }
              }
            }
          }
        }
        .macselect-benefits__initial {
          li {
            width: 100%;
            justify-content: center;
            border: 1px solid $color--border;
          }
          &-item {
            border-top: none !important;
          }
        }
      }
      .macselect-benefits__section-button-container {
        .button {
          font-family: $roboto-condensed-bold-font;
        }
      }
      &-benefits-link {
        margin-top: 40%;
      }
      &__content,
      &__romance {
        @include breakpoint($bp--medium-landscape) {
          margin: 0 10%;
        }
      }
      .loyalty__content-bottom__section-container {
        @include breakpoint($bp--medium-landscape) {
          padding-top: 10%;
        }
        @include breakpoint($bp--medium-portrait) {
          &-content {
            margin: 0 6%;
            .macselect-benefits {
              &__section-fields {
                width: 39%;
              }
              &__section-levels {
                width: 61%;
              }
            }
          }
        }
        &-header {
          width: 100%;
          font-family: $roboto-condensed-bold-font;
          @include breakpoint($bp--medium-landscape) {
            width: 50%;
          }
          @include breakpoint($bp--medium-portrait) {
            width: 50%;
          }
        }
        .macselect-benefits__section {
          &-terms-container__terms-text {
            font-family: $roboto-condensed-bold-font;
          }
        }
      }
      .button {
        line-height: inherit;
      }
    }
  }
  .macselect-benefits__container {
    .macselect-benefits {
      &__section-levels__header-title {
        @include breakpoint($width-large - 1) {
          font-size: 20px;
        }
        @include breakpoint(($bp--large-up) (orientation landscape)) {
          font-size: 27px;
        }
      }
    }
  }
}

.registration-panel-loyalty {
  margin-top: 20px;
  .panel--loyalty-join__logo {
    float: #{$ldirection};
  }
  .loyalty-join-panel {
    border: none;
    .field-container {
      margin-bottom: 7px;
      display: inline-block;
    }
  }
}

.loyalty__panel__offers__sku {
  position: relative;
  &__list-container {
    @include swap_direction(margin, 3% 0 2% 0);
    .tabbed-rewards-block__tab {
      padding: 10px 30px;
    }
    .product-full__shades-grid {
      position: inherit;
      margin-top: 10px;
      max-width: inherit;
    }
    .tabbed-rewards-block__content-item {
      .grid--mpp__items {
        .slick-list {
          width: 94%;
          padding-bottom: 15px;
          .slick-slide {
            @include breakpoint($bp--medium-portrait) {
              max-width: inherit;
            }
          }
        }
      }
    }
  }
  &__cta {
    margin-top: 30px;
    text-align: center;
  }
  &__product__info {
    text-align: center;
  }
  &__product__image {
    margin: 0 auto;
    height: auto;
    &-container {
      border: none !important;
    }
  }
  &__product__offer-code {
    text-align: center;
    position: absolute;
    bottom: 45px;
  }
  &__header-container {
    @include breakpoint($bp--medium-portrait) {
      top: 32%;
    }
  }
  &__product-wrapper {
    text-align: #{$ldirection};
  }
}

#site-header {
  .site-my-mac__contents {
    height: auto;
    &-footer__loyalty {
      display: inline-block;
      width: 100%;
      &.site-my-mac__contents {
        &-footer {
          &:hover {
            background: none;
            color: $color--black;
            .site-my-mac__contents {
              &-footer-link {
                color: $color--black;
              }
            }
          }
        }
      }
    }
    &-footer-link {
      float: $ldirection;
      color: $color--black;
    }
    &-footer-link2 {
      float: $ldirection;
      width: auto;
      color: $color--black;
      clear: both;
    }
    &-footer-title {
      width: 58%;
    }
    &-footer-copy {
      width: 40%;
    }
    &-footer {
      &:hover {
        .site-my-mac__contents {
          &-footer-link {
            color: $color--white;
          }
        }
      }
    }
  }
}

.mac-select__color-tier1 {
  color: $tier1color;
}

.mac-select__color-tier2 {
  color: $tier2color;
}

.mac-select__color-tier3 {
  color: $tier3color;
}

.account-loyalty {
  .mac-select__account-panel {
    &__content-title,
    &__content-points {
      @include breakpoint($width-large - 1) {
        font-size: 18px;
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        font-size: 22px;
      }
    }
    &__header-left {
      width: 55%;
    }
    &__header-right {
      width: 45%;
    }
    &__content-title-right {
      font-size: 35px;
    }
    &__content-points {
      font-size: 22px;
      margin-top: 0;
    }
    .mac-select__color-tier {
      border: none;
    }
    &__content-details {
      clear: both;
    }
    .mac-select__color-tier1 {
      color: $tier1color;
    }
    .mac-select__color-tier2 {
      color: $tier2color;
    }
    .mac-select__color-tier3 {
      color: $tier3color;
    }
  }
}

#loyalty__panel__offers {
  .slick-list {
    width: 100%;
  }
  .product-full__shades {
    height: 220px;
    .product-full__shades-grid {
      position: inherit;
      margin-top: 10px;
      &-item {
        width: 90px;
      }
    }
    .product-full__shade-swatch {
      width: 90px;
      float: $ldirection;
    }
  }
  .loyalty__panel__offers {
    &__button {
      display: inline-block;
    }
    &__remove {
      float: $ldirection;
    }
    &__cta {
      font-family: $loyalty-tstar-font;
      font-weight: bold;
    }
  }
}

#loyalty__panel__transactions {
  .transactions-footer {
    &__view-more-link {
      border: none;
    }
  }
  &.loyalty__panel__history__table {
    .loyalty__panel__transactions {
      &__title {
        font-family: $roboto-condensed-bold-font;
      }
      &__instruction,
      &__level__description,
      &__table__body {
        font-family: $roboto-mono_regular-font;
      }
    }
  }
}

#loyalty-anniversary-panel {
  .product__header,
  .product__image-small {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    max-width: inherit;
  }
}

.select-marketing-page,
.loyalty__content-bottom {
  &.is-eligible {
    .mac-select {
      &__marketing-page__header {
        display: block;
      }
    }
  }
  .mobile-signup {
    .sms_input {
      width: 50%;
      margin: 0 0 3% 5%;
    }
  }
  .loyalty-signin {
    max-width: 660px;
    margin: 0 25%;
    #form--signin {
      &--field--EMAIL_ADDRESS {
        margin: 20px 0;
      }
    }
  }
  .mac-select__marketing-page {
    &__lover {
      @include breakpoint($bp--medium-up) {
        margin: 5% 15%;
      }
      @include breakpoint($bp--large-up) {
        margin: 0 15%;
      }
    }
    &__intro {
      font-family: $roboto-mono_regular-font;
    }
    @include breakpoint($bp--medium-portrait) {
      &__header {
        padding: 0;
      }
      &__lover {
        margin: 5% 10%;
      }
      &__banner-logo {
        width: 30%;
      }
      &__romance {
        @include swap_direction(margin, 2% 10% 5% 10%);
      }
      &__intro {
        margin-#{$rdirection}: 15px;
      }
    }
    &__header-col1 {
      text-align: #{$ldirection};
    }
    &__header-col2 {
      @include breakpoint($bp--medium-up) {
        float: #{$ldirection};
        width: 35%;
      }
      @include breakpoint($bp--large-up) {
        float: #{$rdirection};
        width: auto;
      }
    }
  }
  .loyalty-select {
    .field-container,
    #registration_short {
      .picker-label {
        width: 85%;
      }
    }
    .form-item_birthday {
      .select2-container {
        &.error {
          border: 1px solid $color--red;
        }
      }
    }
  }
  .mac-lover__events__promos {
    &--header {
      width: 100%;
      font-family: $roboto-condensed-bold-font;
      @include breakpoint($width-large - 1) {
        position: relative;
        font-size: 36px;
      }
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        font-size: 50px;
      }
    }
    &--description {
      font-family: $roboto-mono_regular-font;
    }
  }
}

.loyalty-anniversary-panel,
.loyalty-birthday-panel {
  .btn {
    padding: 0 18px;
  }
}

.checkout-panel--loyalty-points {
  display: inline-block;
  text-align: $rdirection;
  width: 93%;
  .loyalty-points-content {
    &__summary__member-content {
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}

#loyalty__page__mac_lover {
  .loyalty__panel__offers__sku {
    &__cta__container {
      display: none;
    }
  }
}

.loyalty__panel__offers,
.loyalty__panel__offers__sku {
  position: relative;
  .product__rating {
    text-align: #{$ldirection};
    min-height: 20px;
  }
  .product-brief-v2 {
    max-width: inherit;
  }
  .slick-list {
    .slick-slide {
      min-height: 650px;
      text-align: #{$ldirection};
      padding: 20px 22px !important;
      @include breakpoint($bp--medium-landscape) {
        min-height: 675px;
      }
      .loyalty__panel__offers {
        &__sku__cta__container,
        &__cta {
          position: absolute;
          bottom: 0;
          text-align: center;
          width: 100%;
          #{$ldirection}: 0;
        }
        &__offer-valid {
          text-align: center;
        }
        &__offer-header {
          font-size: 20px;
          padding-top: 0;
          line-height: inherit;
          min-height: 35px;
          text-align: center;
          font-family: $roboto-condensed-bold-font;
        }
        &__offer-image-container {
          border: none !important;
          margin-bottom: 20px;
        }
        &__sku-description {
          text-align: center;
          font-size: 14px;
          text-transform: uppercase;
          min-height: 45px;
          margin: 5px 0;
        }
        &__offer-description {
          padding-bottom: 10px;
        }
        &__sku__product__sub-header,
        &__offer-description {
          font-size: inherit;
          font-family: inherit;
          min-height: 55px;
          text-align: center;
        }
        &__offer-description,
        &__sku__product__data__header {
          min-height: 55px !important;
          margin-bottom: 0;
          text-align: center;
        }
        &__sku__product {
          padding: 22px 0 !important;
        }
      }
      .product-full__shades {
        min-width: inherit;
        height: auto;
        &-grid {
          max-width: inherit;
          &-item,
          .product-full__shade,
          .shade-item {
            width: 88px;
          }
        }
      }
    }
  }
  .button {
    line-height: inherit;
    padding: 13px 35px;
    width: 90%;
    margin: 0 auto;
    @include breakpoint($bp--medium-portrait) {
      width: 75%;
    }
  }
  .product-brief-carousel {
    &.grid--mpp__carousel {
      .slick-prev {
        position: absolute;
        &::before {
          display: block;
          font-size: 15px;
        }
      }
      .slick-next {
        padding-#{$ldirection}: 0;
      }
    }
  }
  .shade-selector__content {
    min-height: 230px;
    text-align: center;
  }
  .loyalty__panel__offers__sku {
    &__header__subtitle {
      font-family: $roboto-mono_regular-font;
    }
  }
}

.checkout__sidebar {
  .checkout-panel--loyalty-points {
    display: inline-block;
    margin-top: 10px;
    text-align: inherit;
    width: 90%;
  }
}

.product-brief-carousel {
  &.grid--mpp__carousel {
    .slick-prev {
      position: absolute;
    }
  }
}

.product-full__shades {
  @include breakpoint(($bp--large-up) (orientation landscape)) {
    min-width: 265px;
  }
}

.loyalty__panel__offers {
  &__list-container {
    .slick-next {
      @include breakpoint($width-large - 1) {
        #{$ldirection}: 95%;
      }
    }
  }
  &--content {
    @include breakpoint($bp--large-down) {
      width: 50%;
      top: 31%;
      #{$ldirection}: 7%;
    }
    @include breakpoint($bp--medium-landscape) {
      margin-#{$rdirection}: 3%;
      width: 38%;
      margin-top: 7%;
    }
  }
  .product-brief-carousel {
    .offers_2_carousel {
      .product-brief-shades {
        &__shade-item__title {
          margin: 0;
        }
      }
    }
  }
}

.progress_bar {
  @include breakpoint($bp--large-down) {
    @include swap_direction(padding, 2% 12% 3% 12%);
    &_faq {
      clear: both;
      display: block !important;
      margin-top: 2%;
    }
  }
  &_text {
    #{$ldirection}: 70px;
  }
  &_left {
    @include breakpoint($bp--xxlarge-up) {
      width: 70%;
    }
  }
  .points__expiration {
    #{$ldirection}: 34px;
  }
  .total_progress {
    #{$ldirection}: 87px;
  }
  .current_tier_level1 {
    .total_progress {
      #{$ldirection}: 93px;
    }
  }
  &_left--links-faq,
  &_left--links-about {
    width: auto;
    text-decoration: underline;
    border-bottom: none;
  }
  &_right {
    &.current_tier_level4 {
      .total_progress {
        display: none;
      }
    }
  }
}

.enhanced_cart_page {
  .viewcart {
    .checkout__content {
      background: $color--white;
      .accordionPanel {
        background: $color--white;
      }
      #bottom-viewcart-buttons {
        margin-top: 15px;
        margin-bottom: 30px;
      }
    }
  }
  .checkout__sidebar {
    .loyalty-birthday-panel__footer {
      .loyalty-birthday-panel {
        &-btn {
          width: 100%;
          .btn {
            line-height: 1.2;
          }
        }
      }
    }
  }
}

.loyalty-birthday-panel {
  &__footer {
    .disabled {
      pointer-events: none;
      background: $color--gray--lightest;
    }
  }
}

.mac-early-access-popup-colorbox {
  .mac-early-access-popup {
    &__sub-title,
    &__check-back-soon-message,
    &__congratulation-message {
      width: auto;
    }
    &__congratulation-button {
      margin-top: 50px;
    }
    &__earn-more-message {
      padding-bottom: 10px;
    }
  }
}

.mac-select__carouesl-banner {
  @include breakpoint($bp--medium-portrait) {
    display: block !important;
    visibility: visible !important;
  }
}

.checkout-confirmation-page {
  .form-item {
    &.loyal-postal {
      .invalid_marker {
        #{$ldirection}: 375px;
      }
    }
  }
}
