.site-header {
  .site-banner {
    @include breakpoint($bp--medium-up) {
      height: 4.5em;
    }
  }
}

.clear {
  clear: both;
  zoom: 1;
}

.cright {
  clear: right;
}

.cleft {
  clear: left;
}

.clearfix:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.profile-pic-upload__form-wrapper .upload-file {
  left: 0;
}

.tiny-waitlist-overlay {
  .field {
    .email_input {
      input {
        width: 75%;
      }
    }
    .align-r {
      .input-btn {
        width: 90px;
      }
    }
  }
}

.grid--mpp__item {
  .product--teaser {
    .product__header {
      .product__rating {
        max-width: 100px;
        min-width: 100px;
      }
      .product__name {
        padding-right: 95px !important;
      }
    }
    .product__footer {
      ul.product__inventory-status {
        li {
          font-size: 12px;
          width: 70%;
          padding: 0;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        ul.product__inventory-status {
          li,
          a.notify_me {
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            width: 70%;
          }
        }
      }
    }
  }
}

@include breakpoint($bp--large-down) {
  .grid--mpp__item {
    .product--teaser {
      .product__footer {
        ul.product__inventory-status {
          li,
          a.notify_me {
            width: 100%;
          }
        }
      }
    }
  }
}

#guarantee-panel p {
  margin-bottom: 0;
}

#cboxWrapper {
  #cboxContent {
    #cboxClose {
      right: 15px !important;
    }
  }
}

.product--shaded--single {
  .shade-picker-full__colors-mask {
    .shade-picker-full__colors {
      .shade-picker__color-texture {
        height: 60px;
      }
    }
  }
}

.section-culture {
  aside.culture-landing__item {
    .culture-landing__tracks-image {
      padding: 16% 11% 11.8%;
    }
    &:last-child {
      clear: both;
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .site-container {
    .submenu {
      &__header {
        h3 {
          font-size: 26px !important;
        }
      }
    }
  }
  .section-artistry-all-artists {
    .artistry-artists-page-formatter {
      .artistry-artists-page__filter {
        width: 26%;
      }
    }
  }
}

.section-collections-studio-fix {
  .collection-landing-brick__caption {
    margin-top: 0;
  }
}

.artist--products__carousel {
  .product__footer {
    .product_content_fav_mobile {
      .product__inventory-status {
        .js-inv-status-7.sold-out.btn {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}

.site-container {
  #product-page-livechat-hitarea {
    width: 114px;
  }
}

.appt-book {
  .my-appointments {
    .registration-container {
      #my-appts-registration-form {
        .registration__email-container {
          .picker-checkbox {
            display: block !important;
            .picker-handle {
              border-color: black !important;
            }
          }
          .registration__email-list {
            color: black !important;
          }
        }
      }
    }
    .sign-in-container {
      a {
        &.js-forgot-password {
          margin-left: 14%;
        }
      }
      input {
        &.login__submit {
          display: block !important;
        }
      }
    }
  }
  .appt-book-section-content {
    .services {
      .service {
        height: 180px !important;
      }
    }
  }
  .appointment-select {
    .appointments-container {
      .appointment-select {
        .calendar-head {
          .cal-head-controls {
            .cal-controls.previous {
              visibility: visible !important;
              width: 22% !important;
            }
            .cal-controls.day-time {
              width: 53% !important;
            }
            .cal-controls.next {
              width: 25% !important;
            }
          }
        }
      }
    }
  }
}

#appt-book-my-appointments-overlay {
  #appt-canceled {
    .book-new-btn {
      font-size: 16px;
    }
  }
}

@include breakpoint($bp--xlarge-down) {
  .section-book-appointment.top-header {
    .appt-book-page-header {
      header.submenu__header {
        width: 29%;
      }
      .submenu__nav {
        .appointment-nav__link {
          font-size: 16px !important;
        }
      }
    }
  }
}

.appt-book {
  .appt-book-page-header-content {
    .inner {
      padding-top: 100px !important;
    }
  }
  #confirmation {
    .confirmation-container {
      .date-time {
        .appt-date-cal {
          background-position: 7.8em 0.25em;
        }
      }
    }
  }
  .appt-book-sections-container {
    .my-appointments {
      .appointment-container {
        .appointment-details {
          .date-time {
            .appt-date-cal {
              background-position: 7.8em 0.25em;
            }
          }
        }
      }
    }
  }
}

#popover_container {
  .form--newsletter__form-items {
    .form-wrapper {
      .form--newsletter__submit {
        height: 29px !important;
      }
    }
  }
}

.front {
  #cboxContent {
    #cboxLoadedContent {
      overflow: hidden !important;
    }
  }
}

.makeup_studio_only {
  .tout-block-landscape {
    .tout-block-landscape__body-above img {
      width: auto;
    }
  }
}
