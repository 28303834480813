$border_color: #dbdbdb;
$background_color: #d4d4d4;

#recommended-products-panel {
  .checkout__panel-content {
    margin-top: 20px;
  }
  .recommended-product-items {
    .recommended-item {
      position: relative;
      padding-bottom: 30px;
      .column.item_info {
        position: relative;
        .product_name {
          height: 15px;
        }
        .product_subname {
          height: 20px;
        }
      }
      .add_button {
        padding: 10px 0;
      }
    }
    .product_name a {
      border: none;
    }
  }
}

#checkout-sidebar {
  .additional_info {
    margin-top: 1em;
  }
}

.samples {
  .product.deactivate {
    .sample-select-button {
      background-color: $background_color;
      cursor: auto;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .picker-checkbox {
    .picker-handle {
      display: none;
    }
  }
  .samples-panel {
    border-top: 1px solid $border_color;
    padding-top: 15px;
  }
  .checkout__panel-title {
    display: block;
  }
  .sample-select-button {
    width: 125px;
  }
}

.shipping .checkout-progress__shipping,
.review .checkout-progress__review,
.billing .checkout-progress__billing,
.confirm .checkout-progress__confirm {
  font-weight: bold;
}

.checkout {
  ol.checkout-progress {
    li {
      float: left;
      margin-left: 1.5em;
      width: 12.5em;
      list-style-type: decimal;
      margin-top: 1.5em;
    }
  }
  .address-container {
    width: 40%;
    float: left;
  }
  .address_controls {
    #choose-address {
      float: left;
      margin-top: 15px;
    }
  }
  address {
    font-style: normal;
  }
  #review-instructions p {
    margin-top: 15px;
  }
}

.transaction-panel-details {
  margin-top: 2em;
  .trans_detail_item {
    float: left;
    margin-bottom: 2em;
    margin-right: 2em;
    position: relative;
    width: 23.4em;
    word-wrap: break-word;
    .change_link {
      position: absolute;
      top: 0.6em;
      right: 0;
    }
    dd {
      margin: 5px 0 0 10px;
    }
  }
}

.shipping {
  #address_form_container,
  #billing-address-info-panel {
    .form_element {
      margin: 12px 0;
    }
  }
}

.billing {
  .direct-debit label {
    display: block;
  }
  .payment-type {
    float: left;
    margin-right: 0.8em;
  }
  #payment-type-cc {
    margin-top: 15px;
  }
}

.confirm {
  #print-order {
    margin-top: 10px;
  }
}

.checkout__content {
  .cart-item__total,
  .cart-header__title--total {
    width: 20%;
    margin-left: 80%;
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      padding-right: 2%;
    }
  }
  #order-summary-panel {
    .order-summary__content {
      .value {
        width: 20%;
        margin-left: 80%;
      }
    }
  }
}

.checkout-header {
  #top-viewcart-buttons {
    .continue-buttons {
      padding-#{$rdirection}: 2%;
    }
  }
}

#need-help-panel {
  margin-bottom: 1em;
}

.checkout-confirmation-page {
  .registration-panel {
    .messages-container {
      .single-message {
        &.MESSAGE {
          color: $color--red--light;
        }
      }
    }
  }
}
