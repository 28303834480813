#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Faydalı(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Yardım etmeyen(';
              }
            }
          }
          .pr-rd-flag-review-container {
            margin-right: 12px;
            line-height: 1.5;
          }
        }
      }
    }
    .pr-review-snapshot-block-container {
      .pr-review-snapshot-block {
        .pr-review-snapshot-snippets {
          .pr-snippet-stars-reco-inline {
            .pr-snippet-stars-reco-stars {
              .pr-snippet {
                .pr-snippet-read-and-write {
                  .pr-snippet-write-review-link {
                    padding: 12px 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          display: table;
          margin: 0 0 8px 0;
          &:before {
            content: 'HEPSİNİ OKU ';
          }
        }
      }
      .pr-snippet-stars-container {
        &:before {
          content: 'GENEL PUANLAMA:';
        }
      }
    }
  }
}
